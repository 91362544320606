
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Box, Heading } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { configFile } from "@/config";
export default function FourOhFour() {
    const { t } = useTranslation("common");
    return (<>
      <Box height={{ base: "400px", md: "calc(100vh - 448px)" }} padding="0px 20px" display="flex" justifyContent="center" alignItems="center" flexDirection="column" rowGap="32px">
        <Heading textAlign="center" as="h1">
          {t("pageNotFound.title")}
        </Heading>
        <Link prefetch={false} href="/" style={{
            color: configFile.primaryAccentColor,
            fontWeight: "400"
        }}>
          {t("pageNotFound.button")}
        </Link>
      </Box>
    </>);
}

    async function __Next_Translate__getStaticProps__19485113662__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19485113662__ as getStaticProps }
  